<template>
  <div class="home">
    <div class="header">
      <div class="nav">
        <div class="navBox">
          <p>青岛七星国际会展科技有限公司</p>
          <div class="navBar">
            <ul>
              <li @click="$router.push('/')">首页</li>
              <li @click="toPage('/goodslist')">商品中心</li>
              <li v-if="totalUnreadCount > 99" @click="toim">我的消息(99+)</li>
              <li v-else-if="totalUnreadCount !== 0" @click="toim">
                我的消息({{ totalUnreadCount }})
              </li>
              <li v-else @click="toim">我的消息</li>
              <li @click="toPage('/myFavorites')">我的收藏</li>
              <li @click="toPage('/myOrders')">我的订单</li>
              <li @click="toPage('/shoppingCartItem')">
                购物车
                <span v-if="$store.getters.getnum">{{ $store.getters.getnum }}</span>
              </li>
            </ul>
            <div class="info">
              <el-avatar
                :src="$store.getters.getavatar"
                v-if="$store.getters.getavatar && $store.getters.getavatar !== ''"
              ></el-avatar>
              <el-dropdown v-if="user !== '' && user">
                <span class="el-dropdown-link">
                  <span>{{ user }}</span>
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="toPage('/myProfile')"
                    >会员中心</el-dropdown-item
                  >
                  <el-dropdown-item divided @click.native="logout"
                    >退出登录</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <span class="el-dropdown-link" @click="toPage('/login')" v-else>
                请登录
              </span>
            </div>
            <p class="tel">{{ $store.getters.gettel }}</p>
          </div>
        </div>
      </div>
      <div class="search content">
        <img src="../assets/img/Component 1.png" @click="$router.push('/')" alt="" />
        <el-input v-model="searchText" placeholder="想要什么？搜搜看"></el-input>
        <el-button type="primary" @click="search">搜索</el-button>
      </div>
    </div>
    <div class="main">
      <router-view @bz_frame_id="bz" />
    </div>
    <div class="footer">
      <div class="content footbox">
        <div class="company">
          <p>
            <a @click="topage('/pc_agree')">用户协议</a><span></span
            ><a @click="topage('/pc_policy')">隐私政策</a><span></span
            ><a @click="topage('/activity', bz_frame_id)">帮助中心</a>
          </p>
          <p>Copyright© 青岛七星国际会展科技有限公司 版权所有 备案号：<a href="https://beian.miit.gov.cn" target="_blank">鲁ICP备15022445号-2</a></p>
          <p style="font-size: 14px">
            注册地址：青岛市崂山区海口路33号麦岛家园5号楼2108室 电话：0532-68691313
          </p>
        </div>
        <div class="code">
          <p>扫码关注公众号</p>
          <img src="../assets/img/weixin.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      searchText: "",
      bz_frame_id: 0,
    };
  },
  computed: {
    ...mapGetters(["totalUnreadCount"]),
    user() {
      return localStorage.getItem("nickname");
    },
    sid() {
      return JSON.parse(localStorage.getItem("sid"));
    },
  },
  methods: {
    bz(data) {
      this.bz_frame_id = data;
    },
    toPage(page) {
      this.$router.push(page);
    },
    topage(page, id, item) {
      let routeData = this.$router.resolve({ path: page, query: { id: id, item: item } });
      window.open(routeData.href, "_blank");
    },
    toim() {
      this.$emit("toim", "");
    },
    logout() {
      const that = this;
      that.$http
        .postApi("/logout", { member_id: localStorage.getItem("member_id") })
        .then((res) => {
          if (res.data.code === 200) {
            that.$store.commit("saveavatar", "");
            localStorage.clear();
            this.$store.dispatch("logout");
            location.reload();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    search() {
      if (this.searchText !== "" && this.$route.path !== "/goodslist") {
        this.$router.push({
          path: "/goodslist",
          query: { q: encodeURI(this.searchText) },
        });
      } else {
        this.$router.push({
          //替换所有参数
          query: { q: encodeURI(this.searchText) },
        });
      }
    },
    getinfo() {
      const that = this;
      const data = {
        member_id: localStorage.getItem("member_id"),
      };
      that.$http
        .postApi("/member_info", data)
        .then((res) => {
          if (res.data.code === 200) {
            that.$store.commit("savenum", res.data.data.cars_num);
            that.$store.commit("saveavatar", res.data.data.pic);
            localStorage.setItem("sid", JSON.stringify(res.data.data));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    if (typeof this.$route.query.q !== "undefined") {
      this.searchText = decodeURI(this.$route.query.q);
    }
    if (localStorage.getItem("token") && localStorage.getItem("token") !== "") {
      this.getinfo();
    } else {
      this.$store.commit("savenum", 0);
    }
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.home {
  min-width: 1200px;
}
.header {
  width: 100%;
  .nav,
  .navBar,
  .navBar ul,
  .info,
  .search {
    display: flex;
    align-items: center;
  }
  .nav {
    justify-content: center;
    height: 56px;
    background: #101b28;
    color: #ffffff;
    // padding: 0 17.75%;
    .navBox {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1200px;
    }
    p {
      font-size: 14px;
      font-weight: 700;
      line-height: 32px;
    }
    .navBar {
      margin-left: auto;
      ul {
        li {
          font-size: 14px;
          line-height: 18px;
          margin-right: 32px;
          cursor: pointer;
          span {
            display: inline-block;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background: #ff863d;
            font-size: 8px;
            text-align: center;
            line-height: 14px;
            vertical-align: middle;
          }
        }
      }
      .info {
        line-height: 18px;
        margin-right: 32px;
        .el-avatar {
          width: 32px;
          height: 32px;
          /deep/img {
            width: 100%;
            // height: auto;
          }
        }
        .el-dropdown-link {
          cursor: pointer;
          color: white;
          margin-left: 8px;
          display: inline-block;
          span {
            display: inline-block;
            width: 70px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .el-icon-arrow-down {
          font-size: 12px;
        }
      }
    }
    .tel {
      background-image: -webkit-linear-gradient(97deg, #3f7fff 0%, #7cc0ff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 36px;
      font-family: "SedanSC";
    }
  }
  .search {
    height: 96px;
    // padding: 0 18.75%;
    background: #ffffff;
    img {
      width: 72px;
      height: 72px;
      cursor: pointer;
    }
    .el-input {
      max-width: 496px;
      height: 40px;
      border: 2px solid #3d7eff;
      margin-left: 225px;
      margin-right: 24px;
      /deep/ input {
        border-radius: 0;
      }
    }
    .el-button {
      width: 96px;
      height: 44px;
      background: #3d7eff;
      border-radius: 0;
    }
  }
}
.main {
  background-color: #f5f5f6;
}
.footer {
  width: 100%;
  background: #101b28;
  .footbox {
    height: 227px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .company {
    text-align: center;
    flex: 1;
    p {
      font-weight: 700;
      color: #97a5b7;
      line-height: 18px;
      a {
        color: #97a5b7;
        cursor: pointer;
      }
      span {
        background-color: #97a5b7;
        width: 1px;
        height: 18px;
        display: inline-block;
        margin: 0 32px;
      }
    }
    p + p {
      margin-top: 32px;
    }
  }
  .code {
    text-align: right;
    p {
      font-weight: 700;
      text-align: LEFT;
      color: #ffffff;
      line-height: 18px;
      margin-bottom: 17px;
    }
    img {
      width: 120px;
      height: 120px;
    }
  }
  p {
    color: #97a5b7;
  }
}
</style>