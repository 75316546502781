<template>
  <div id="app">
    <router-view @toim="toim" />
    <div class="contact">
      <div class="org" @click="toIM">
        <img src="./assets/img/Iconly_Light_Chat.png" alt="" />
        <p>洽谈合作</p>
      </div>
      <div @click="toIM">
        <img src="./assets/img/Iconly_Light_Profile.png" alt="" />
        <p>在线客服</p>
      </div>
      <div class="tel">
        <img src="./assets/img/Iconly_Light_Call1.png" alt="" />
        <p>联系电话</p>
        <div class="wtes">Tel: {{ tel }}</div>
      </div>
      <div class="wte" @click="totop">
        <img src="./assets/img/Iconly_Light_Arrow - Up.png" alt="" />
        <p>返回顶部</p>
      </div>
    </div>
    <tencent v-show="contentShow" @close="closeDialog"></tencent>
  </div>
</template>
<script>
import { mapState } from "vuex";
import MTA from "./utils/mta";
import tencent from "./components/tencentIM/index.vue";
export default {
  components: {
    tencent,
  },
  data() {
    return {
      contentShow: false,
      tel: null,
      im: function () {
        this.contentShow = true;
      },
    };
  },
  created() {
    if (sessionStorage.getItem("store")) {
      // 刷新覆盖vuex
      this.$store.replaceState(
        Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem("store")))
      );
    }
    window.addEventListener("beforeunload", () => {
      // 将vuex存到缓存中
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  },
  methods: {
    toim() {
      this.toIM();
    },
    toIM() {
      if (!localStorage.getItem("member_id")) {
        this.$router.push("/login");
      } else {
        this.contentShow = true;
      }
    },
    closeDialog() {
      this.contentShow = false;
    },
    totop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    getData() {
      const that = this;
      that.$http
        .postApi("/index", {})
        .then((res) => {
          if (res.data.code === 200) {
            that.tel = res.data.data.officer_tel;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getData();
    if (localStorage.getItem('member_id')) {
      this.$store.dispatch("login");
    }
  },
  computed: {
    ...mapState({
      isLogin: (state) => state.user.isLogin,
    }),
  },
  watch: {
    isLogin(next) {
      if (next) {
        MTA.clickStat("link_two", { show: "true" });
      }
    },
  },
};
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
  font-family: PingFang SC, PingFang SC-Bold;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}
.content {
  // padding: 0 15%;
  // width: calc(100% - 30%);
  width: 1200px;
  margin: 0 auto;
}
.contact {
  z-index: 9997;
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  div {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 88px;
    height: 96px;
    background: #3d7eff;
    box-shadow: -6px 4px 20px 0px rgba(61, 126, 255, 0.3);
    cursor: pointer;
    p {
      font-size: 12px;
      font-weight: 700;
      text-align: LEFT;
      color: #ffffff;
      line-height: 18px;
    }
  }
  div.org {
    background: #ff863d;
    box-shadow: -6px 4px 20px 0px rgba(176, 103, 60, 0.3);
  }
  div.wte {
    box-shadow: -6px 4px 20px 0px rgba(151, 165, 183, 0.2);
    background: #ffffff;
    margin-top: 8px;
    p {
      color: #525b66;
    }
  }
  div + div {
    margin-top: 8px;
  }
  .tel {
    position: relative;
    .wtes {
      position: absolute;
      background-color: #fff;
      font-size: 18px;
      width: 250px;
      left: 0;
      transition: 0.4s ease-out;
      box-shadow: none;
      z-index: -1;
      &::after {
        content: "";
        position: absolute;
        top: 38px;
        left: 250px;
        border-top: 9px solid transparent;
        border-left: 10px solid #fff;
        border-bottom: 9px solid transparent;
      }
    }
    &:hover {
      .wtes {
        box-shadow: -6px 4px 20px 0px rgba(151, 165, 183, 0.2);
        left: -264px;
      }
    }
  }
}
</style>
