<template>
  <div class="list-container">
    <div class="header-bar">
      <!-- <button title="刷新列表" @click="handleRefresh">
        <i class="tim-icon-refresh"></i>
      </button> -->
      <!-- <button title="创建会话" @click="handleAddButtonClick">
        <i class="tim-icon-add"></i>
      </button> -->
      <div class="telLine">
        <img src="../../../assets/img/Iconly_Light_Call@2x.png" alt="">
        <p>客服热线<span>{{ $store.getters.gettel }}</span></p>
        <!-- <p>客服热线<span>400-966-0622</span></p> -->
      </div>
    </div>
    <div class="scroll-container">
      <conversation-item
        :conversation="item"
        v-for="item in conversationList"
        :key="item.conversationID"
      />
    </div>
    <el-dialog title="快速发起会话" :visible.sync="showDialog" width="30%">
      <el-input placeholder="请输入用户ID" v-model="userID" @keydown.enter.native="handleConfirm"/>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">取 消</el-button>
        <el-button type="primary" @click="handleConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ConversationItem from './conversation-item'
import { mapState } from 'vuex'
export default {
  name: 'ConversationList',
  components: { ConversationItem },
  data() {
    return {
      showDialog: false,
      userID: '',
      isCheckouting: false, // 是否正在切换会话
      timeout: null
    }
  },
  computed: {
    ...mapState({
      conversationList: state => state.conversation.conversationList,
      currentConversation: state => state.conversation.currentConversation
    }),
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeydown)
    // this.getTel()
  },
  destroyed() {
    window.removeEventListener('keydown', this.handleKeydown)
  },
  methods: {
    getTel() {
      const that = this
      that.$http.postApi('/PcBusofficerlist', {}).then(res => {
        if (res.data.code === 200) {
          res.data.data.list.forEach(ele => {
            that.handleConfirms(ele.tengcloudid)
          }).catch(err => {
            console.log(err)
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    handleRefresh() {
      this.refreshConversation()()
    },
    refreshConversation() {
      let that = this
      return function () {
        if (!that.timeout) {
          that.timeout = setTimeout(() =>{
            that.timeout = null
            that.tim.getConversationList().then(() => {
              that.$store.commit('showMessage', {
                message: '刷新成功',
                type: 'success'
              })
            })
          }, 1000)
        }
      }
    },
    handleAddButtonClick() {
      this.showDialog = true
    },
    handleConfirms(id) {
      if (id !== '@TIM#SYSTEM') {
        this.$store
          .dispatch('checkoutConversation', `C2C${id}`)
          .then(() => {
          }).catch(() => {
          this.$store.commit('showMessage', {
            message: '没有找到该用户',
            type: 'warning'
          })
        })
      } else {
        this.$store.commit('showMessage', {
          message: '没有找到该用户',
          type: 'warning'
        })
      }
    },
    handleConfirm() {
      if (this.userID !== '@TIM#SYSTEM') {
        this.$store
          .dispatch('checkoutConversation', `C2C${this.userID}`)
          .then(() => {
            this.showDialog = false
          }).catch(() => {
          this.$store.commit('showMessage', {
            message: '没有找到该用户',
            type: 'warning'
          })
        })
      } else {
        this.$store.commit('showMessage', {
          message: '没有找到该用户',
          type: 'warning'
        })
      }
      this.userID = ''
    },
    handleKeydown(event) {
      if (event.keyCode !== 38 && event.keyCode !== 40 || this.isCheckouting) {
        return
      }
      const currentIndex = this.conversationList.findIndex(
        item => item.conversationID === this.currentConversation.conversationID
      )
      if (event.keyCode === 38 && currentIndex - 1 >= 0) {
        this.checkoutPrev(currentIndex)
      }
      if (
        event.keyCode === 40 &&
        currentIndex + 1 < this.conversationList.length
      ) {
        this.checkoutNext(currentIndex)
      }
    },
    checkoutPrev(currentIndex) {
      this.isCheckouting = true
      this.$store
        .dispatch(
          'checkoutConversation',
          this.conversationList[currentIndex - 1].conversationID
        )
        .then(() => {
          this.isCheckouting = false
        })
        .catch(() => {
          this.isCheckouting = false
        })
    },
    checkoutNext(currentIndex) {
      this.isCheckouting = true
      this.$store
        .dispatch(
          'checkoutConversation',
          this.conversationList[currentIndex + 1].conversationID
        )
        .then(() => {
          this.isCheckouting = false
        })
        .catch(() => {
          this.isCheckouting = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.list-container{
  height: 100%;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column; // -reverse
  .header-bar{
    flex-shrink: 0;
    height: 37px;
    border-bottom: 1px solid #f5f5f6;
    padding: 10px 10px 10px 20px;
    button{
      float: right;
      display: inline-block;
      cursor: pointer;
      background: #303841;
      border: none;
      color: #76828c;
      box-sizing: border-box;
      transition: .3s;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      margin: 0 10px 0 0;
      padding: 0;
      width: 30px;
      height: 30px;
      line-height: 34px;
      font-size: 24px;
      text-align: center;
      white-space: nowrap;
      border-radius: 50%;
      outline: 0;
      &:hover{
        // background #5cadff
        // color white
        transform: rotate(360deg);
        color: #5cadff;
      }
    }
  }
  .scroll-container{
    overflow-y: scroll;
    flex: 1;
  }
}
.bottom-circle-btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.refresh {
  bottom: 70px;
}
.telLine{
  display: flex; 
  height: 30px;
  align-items: center;
  img{
    width: 16px;
    height: 16px;
  }
  p{
    font-size: 12px;
    font-family: PingFang SC, PingFang SC-Bold;
    font-weight: 700;
    color: #525b66;
    line-height: 18px;
    letter-spacing: 0px;
    margin-left: 8px;
    text-shadow: 2px 2px 30px 0px rgba(61,126,255,0.30);
    span {
      margin-left: 16px;
    }
  }
}
</style>
