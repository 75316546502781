<template>
  <div class="current-conversation-wrapper">
    <div class="current-conversation" @scroll="onScroll">
      <div class="header">
        <div class="name">{{ name }}</div>
        <!-- <my-profile style="margin-left:auto" /> -->
        <!-- <el-button type="text" style="fontSize: 12px" @click="uploadImg">更换</el-button> -->
        <img src="../../../assets/img/xiaoxi_chat_back@2x.png" style="margin-left:auto" alt="" class="closeBtn" @click="closeDialog">
      </div>
      <div class="contents" v-if="showCurrentConversation">
        <div class="message-list" ref="message-list" @scroll="this.onScroll">
          <div class="more" v-if="!isCompleted">
            <el-button
              type="text"
              @click="$store.dispatch('getMessageList', currentConversation.conversationID)"
            >查看更多</el-button>
          </div>
          <div class="no-more" v-else>没有更多了</div>
          <message-item v-for="message in currentMessageList" :key="message.ID" :message="message"/>
        </div>
        <div v-show="isShowScrollButtomTips" class="newMessageTips" @click="scrollMessageListToButtom">回到最新位置</div>
      </div>
      <div class="footer" v-if="showMessageSendBox && showCurrentConversation" >
        <message-send-box/>
      </div>
    </div>
    <input
      type="file"
      id="imagePicker"
      ref="imagePicker"
      accept=".jpg, .jpeg, .png, .gif, .bmp"
      @change="sendImage"
      style="display:none"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import MessageSendBox from '../message/message-send-box'
import MessageItem from '../message/message-item'
import myProfile from '../my-profile.vue'
export default {
  name: 'CurrentConversation',
  components: {
    MessageSendBox,
    MessageItem,
    myProfile
  },
  data() {
    return {
      isShowScrollButtomTips: false,
      preScrollHeight: 0,
      timeout: ''
    }
  },
  computed: {
    ...mapState({
      currentConversation: state => state.conversation.currentConversation,
      currentUnreadCount: state => state.conversation.currentConversation.unreadCount,
      currentMessageList: state => state.conversation.currentMessageList,
      isCompleted: state => state.conversation.isCompleted
    }),
    ...mapGetters(['toAccount', 'hidden']),
    // 是否显示当前会话组件
    showCurrentConversation() {
      return !!this.currentConversation.conversationID
    },
    name() {
      if (this.currentConversation.type === 'C2C') {
        // return this.currentConversation.userProfile.nick || this.toAccount
        return this.currentConversation.userProfile.nick || 'nickname'
      } else if (this.currentConversation.type === 'GROUP') {
        return this.currentConversation.groupProfile.name || this.toAccount
      } else if (this.currentConversation.conversationID === '@TIM#SYSTEM') {
        return '系统通知'
      }
      return this.toAccount
    },
    showMessageSendBox() {
      return this.currentConversation.type !== this.TIM.TYPES.CONV_SYSTEM
    }
  },
  mounted() {
    this.$bus.$on('image-loaded', this.onImageLoaded)
    this.$bus.$on('scroll-bottom', this.scrollMessageListToButtom)
    if (this.currentConversation.conversationID === '@TIM#SYSTEM') {
      return false
    }
  },
  watch: {
    currentUnreadCount(next) {
      if (!this.hidden && next > 0) {
        this.tim.setMessageRead({ conversationID: this.currentConversation.conversationID })
      }
    },
    hidden(next) {
      if (!next && this.currentUnreadCount > 0) {
        this.tim.setMessageRead({ conversationID: this.currentConversation.conversationID })
      }
    }
  },
  methods: {
    sendImage(e) {
      var that = this;
      var formData = new FormData();
      formData.append("file", e.target.files[0]);
      that.$http
        .postFormAPI("/uploadImg", formData)
        .then((res) => {
          if (res.data.code === 200) {
            let url =
              "https://ceshi.api.yuangtech.com/" +
              res.data.data +
              "?v=" +
              Math.round(Math.random() * 5);
              const options = {
                avatar: url
              }
              that.tim
              .updateMyProfile(options)
              .then(() => {
                that.$store.commit('showMessage', {
                  message: '修改成功'
                })
              })
              .catch(imError => {
                that.$store.commit('showMessage', {
                  message: imError.message,
                  type: 'error'
                })
              })
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    uploadImg() {
      this.$refs.imagePicker.click()
    },
    closeDialog() {
      this.$emit('close', '')
    },
    onScroll({ target: { scrollTop } }) {
      let messageListNode = this.$refs['message-list']
      if (!messageListNode) {
        return
      }
      if (this.preScrollHeight - messageListNode.clientHeight - scrollTop < 20) {
        this.isShowScrollButtomTips = false
      }
    },
    // 如果滚到底部就保持在底部，否则提示是否要滚到底部
    keepMessageListOnButtom() {
      let messageListNode = this.$refs['message-list']
      if (!messageListNode) {
        return
      }
      // 距离底部20px内强制滚到底部,否则提示有新消息
      if (this.preScrollHeight - messageListNode.clientHeight - messageListNode.scrollTop < 20) {
        this.$nextTick(() => {
          messageListNode.scrollTop = messageListNode.scrollHeight
        })
        this.isShowScrollButtomTips = false
      } else {
        this.isShowScrollButtomTips = true
      }
      this.preScrollHeight = messageListNode.scrollHeight
    },
    // 直接滚到底部
    scrollMessageListToButtom() {
      this.$nextTick(() => {
        let messageListNode = this.$refs['message-list']
        if (!messageListNode) {
          return
        }
        messageListNode.scrollTop = messageListNode.scrollHeight
        this.preScrollHeight = messageListNode.scrollHeight
        this.isShowScrollButtomTips = false
      })
    },
    onImageLoaded() {
      this.keepMessageListOnButtom()
    }
  }
}
</script>

<style lang="scss" scoped>
/* 当前会话的骨架屏 */
.current-conversation-wrapper{
  height: 80vh;
  background-color: #f5f5f6;
  color: #1c2438;
  display: flex;
  .current-conversation{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80vh;
  }
  .profile{
    height: 80vh;
    overflow-y: scroll;
    width: 220px;
    border-left: 1px solid #e7e7e7;
    flex-shrink: 0;
  }
  .more{
    display: flex;
    justify-content: center;
    font-size: 12px;
  }
  .no-more{
    display: flex;
    justify-content: center;
    color: #a5b5c1;
    font-size: 12px;
    padding: 10px 10px;
  }
}
.header{
  border-bottom: 1px solid #f5f5f6;
  height: 57px;
  background-color: white;
  position: relative;
  display: flex; 
  align-items: center;
  .name{
    padding: 0 20px;
    color: #1c2438;
    font-size: 18px;
    font-weight: bold;
    line-height: 50px;
    text-shadow: #76828c 0 0 0.1em;
    padding-left: 0;
  }
  .btn-more-info{
    position: absolute;
    top: 10px;
    right: -15px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
    &::before{
      position: absolute;
      right: 0;
      z-index: 0;
      content: "";
      width: 15px;
      height: 30px;
      border: 1px solid #e7e7e7;
      border-radius: 0 100% 100% 0/50%;
      border-left: none;
      background-color: #f5f5f5;
    }
    &::after {
      content: "";
      width: 8px;
      height: 8px;
      transition: transform 0.8s;
      border-top: 2px solid #a5b5c1;
      border-right: 2px solid #a5b5c1;
      float:right;
      position:relative;
      top: 11px;
      right: 8px;
      transform:rotate(45deg)
    } 
    &.left-arrow{
      transform: rotate(180deg);
      &::before{
        background-color: white;
      }
    }
    &:hover{
      &::after{
        border-color: #5cadff;
      }
    } 
  }
  .closeBtn{
    width: 32px;
    height: 32px;
    margin: 0 30px;
    cursor: pointer;
  }
}
.contents{
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
  .message-list{
    width: 100%;
    box-sizing: border-box;
    overflow-y: scroll;
    padding: 0 20px;
  }
  .newMessageTips{
    position: absolute;
    cursor: pointer;
    padding: 5px;
    width: 120px;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 5px;
    font-size: 12px;
    text-align: center;
    border-radius: 10px;
    border: #e9eaec 1px solid;
    background-color: white;
    color: #2d8cf0;
  }
}
.footer{
  border-top: 1px solid #e7e7e7;
}
.show-more {
  text-align: right;
  padding: 10px 20px 0 0;
}
</style>
