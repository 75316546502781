import http from "./http";
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let resquest = "/apis";

// 适合分类导出
// get请求
// export function getlistAPI(params) {
//     return http.get(`${request}/getList.json`, params)
// }
// post请求
export function postApis(url, params){
    return http.post(`${resquest}${url}`,params)
}
// // put 请求
// export function putSomeAPI(params){
//     return http.put(`${resquest}/putSome.json`,params)
// }
// // delete 请求
// export function deleteListAPI(params){
//     return http.delete(`${resquest}/deleteList.json`,params)
// }

// 使用全部导出
export default {
  getListAPI(url, params) {
    return http.get(`${resquest}${url}`, params);
  },
  postFormAPI(url, params) {
    return http.postApi(`${resquest}${url}`, params);
  },
  // url 请求地址 params 请求参数
  postApi(url, params) {
    return http.post(`${resquest}${url}`, params);
  },
};
