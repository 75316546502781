<template>
    <div
        class="conversation-item-container"
        v-if="conversation.type !==  TIM.TYPES.CONV_SYSTEM"
        :class="{ 'choose': conversation.conversationID === currentConversation.conversationID }"
        @click="selectConversation"
    >
      <!-- <div class="close-btn">
        <span class="tim-icon-close" title="删除会话" @click="deleteConversation"></span>
      </div> -->
      <div class="warp">
        <avatar :src="avatar" size="large" :type="conversation.type" />
        <div class="contents">
          <div class="row-1">
            <div class="name">
              <div class="text-ellipsis">
                <span :title="conversation.userProfile.nick || conversation.userProfile.userID"
                  v-if="conversation.type ===  TIM.TYPES.CONV_C2C"
                  >
                  <!-- {{conversation.userProfile.nick || conversation.userProfile.userID}} -->
                  {{conversation.userProfile.nick || 'nickname'}}
                </span>
                <span
                  v-else-if="conversation.type === TIM.TYPES.CONV_SYSTEM"
                  >系统通知
                </span>
                <div class="date">
                  {{date}}
                </div>
              </div>
            </div>
            <div class="unread-count">
              <span class="badge" v-if="showUnreadCount">
                {{conversation.unreadCount > 99 ? '99+' : conversation.unreadCount}}
              </span>
            </div>
          </div>
          <div class="row-2">
            <div class="summary">
              <div v-if="conversation.lastMessage" class="text-ellipsis">
                <span class="remind" style="color:red;" v-if="hasMessageAtMe">[有人提到我]</span>
                <span class="text" :title="conversation.lastMessage.messageForShow">
                  {{messageForShow}}
                </span>
              </div>
            </div>
            <!-- <div class="date">
              {{date}}
            </div> -->
          </div>
        </div>
      </div>
    </div>

</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { isToday, getDate, getTime } from '../../../utils/date'
export default {
  name: 'conversation-item',
  props: ['conversation'],
  data() {
    return {
      popoverVisible: false,
      hasMessageAtMe: false
    }
  },
  computed: {
    showUnreadCount() {
      if (this.$store.getters.hidden) {
        return this.conversation.unreadCount > 0
      }
      // 是否显示未读计数。当前会话和未读计数为0的会话，不显示。
      return (
        this.currentConversation.conversationID !==
          this.conversation.conversationID && this.conversation.unreadCount > 0
      )
    },
    date() {
      if (
        !this.conversation.lastMessage ||
        !this.conversation.lastMessage.lastTime
      ) {
        return ''
      }
      const date = new Date(this.conversation.lastMessage.lastTime * 1000)
      if (isToday(date)) {
        return getTime(date)
      }
      return getDate(date)
    },
    avatar: function() {
      switch (this.conversation.type) {
        case 'C2C':
          return this.conversation.userProfile.avatar
        default:
          return ''
      }
    },
    conversationName: function() {
      if (this.conversation.type === this.TIM.TYPES.CONV_C2C) {
        return this.conversation.userProfile.nick || this.conversation.userProfile.userID
      }
      if (this.conversation.type === this.TIM.TYPES.CONV_SYSTEM) {
        return '系统通知'
      }
      return ''
    },
    messageForShow() {
      if (this.conversation.lastMessage.isRevoked) {
        if (this.conversation.lastMessage.fromAccount === this.currentUserProfile.userID) {
          return '你撤回了一条消息'
        }
        if (this.conversation.type === this.TIM.TYPES.CONV_C2C) {
          return '对方撤回了一条消息'
        }
        return `${this.conversation.lastMessage.fromAccount}撤回了一条消息`
      }
      return this.conversation.lastMessage.messageForShow
    },
    ...mapState({
      currentConversation: state => state.conversation.currentConversation,
      currentUserProfile: state => state.user.currentUserProfile
    }),
    ...mapGetters(['toAccount'])
  },
  mounted() {
    this.$bus.$on('new-messsage-at-me', event => {
      if (
        event.data.conversationID === this.conversation.conversationID &&
        this.conversation.conversationID !==
          this.currentConversation.conversationID
      ) {
        this.hasMessageAtMe = true
      }
    })
  },
  methods: {
    selectConversation() {
      if (this.conversation.conversationID !== this.currentConversation.conversationID) {
        this.$store.dispatch(
          'checkoutConversation',
          this.conversation.conversationID
        )
        this.$bus.$emit('scroll-bottom')
      }
    },
    deleteConversation(event) {
      // 停止冒泡，避免和点击会话的事件冲突
      event.stopPropagation()
      this.tim
        .deleteConversation(this.conversation.conversationID)
        .then(() => {
          this.$store.commit('showMessage', {
            message: `会话【${this.conversationName}】删除成功!`,
            type: 'success'
          })
          this.popoverVisible = false
          this.$store.commit('resetCurrentConversation')
        })
        .catch(error => {
          this.$store.commit('showMessage', {
            message: `会话【${this.conversationName}】删除失败!, error=${error.message}`,
            type: 'error'
          })
          this.popoverVisible = false
        })
    },
    showContextMenu() {
      this.popoverVisible = true
    },
  },
  watch: {
    currentConversation(next) {
      if (next.conversationID === this.conversation.conversationID) {
        this.hasMessageAtMe = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>


.conversation-item-container {
  padding: 15px 20px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: .2s;
  // &:first-child{
    //   padding-top: 30px;
  // }
  &:hover{
    background-color: #f5f5f6;
    .close-btn{
      right: 3px;
    }
      
  }
  .close-btn{
    position: absolute;
    right: -20px;
    top: 3px;
    color: #76828c;
    transition: all .2s ease;
    &:hover{
      color: #f35f5f;
    }
  }
  .warp{
    display: flex;
  }
  .avatar{
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 8px;
    flex-shrink: 0;
  }
  .contents{
    flex: 1;
    height: 40px;
    overflow: hidden;
    .row-1{
      display: flex;
      line-height: 21px;
      .name{
        color: #000000;
        flex: 1;
        min-width: 0px;
      }
      .unread-count{
        padding-left: 10px;
        flex-shrink: 0;
        color: #76828c;
        font-size: 12px;
        .badge{
          vertical-align: bottom;
          background-color: #f35f5f;
          border-radius: 10px;
          color: #FFF;
          display: inline-block;
          font-size: 12px;
          height: 18px;
          max-width: 40px;
          line-height: 18px;
          padding: 0 6px;
          text-align: center;
          white-space: nowrap;
        } 
      }
      .date{
        // padding-left 10px
        // flex-shrink 0
        // text-align right
        // color $font-dark
        float: right;
        display: inline-block;
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #97a5b7;
      }
    }
    .row-2{
      display: flex;
      font-size: 12px;
      padding-top: 3px;
      .summary{
        flex: 1;
        overflow: hidden;
        min-width: 0px;
        color: #525b66;
        .remind{
          color: #f35f5f;
        }
      }
    }
  }
}
.choose {
  background-color: #f5f5f6;
}
.context-menu-button {
  padding: 10px;
  border: 2px solid #2d8cf0;
  border-radius: 8px;
}
</style>
