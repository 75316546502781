import tim from '../../tim'
import {
  postApis
} from "../../components/utils/api";
import {
  Message
} from 'element-ui'
const user = {
  state: {
    currentUserProfile: {},
    isLogin: false,
    isSDKReady: false, // TIM SDK 是否 ready
    userID: 0,
    userSig: '',
    sdkAppID: 0,
  },
  mutations: {
    updateCurrentUserProfile(state, userProfile) {
      state.currentUserProfile = userProfile
    },
    toggleIsLogin(state, isLogin) {
      state.isLogin = typeof isLogin === 'undefined' ? !state.isLogin : isLogin
    },
    toggleIsSDKReady(state, isSDKReady) {
      state.isSDKReady = typeof isSDKReady === 'undefined' ? !state.isSDKReady : isSDKReady
    },
    reset(state) {
      Object.assign(state, {
        currentUserProfile: {},
        isLogin: false,
        isSDKReady: false // TIM SDK 是否 ready
      })
    },
    GET_USER_INFO(state, payload) {
      state.userID = payload.userID
      state.userSig = payload.userSig
      state.sdkAppID = payload.sdkAppID
    },
  },
  actions: {
    getTel({
      state,
      commit,
      dispatch
    }) {
      // return new Promise(resolve => {
      postApis('/PcBusofficerlist', {}).then(res => {
        if (res.data.code === 200) {
          setTimeout(() => {
            res.data.data.list.forEach((ele) => {
              if (ele.tengcloudid !== JSON.parse(localStorage.getItem('sign')).userID) {
                dispatch('checkoutConversation', `C2C${ele.tengcloudid}`)
                  .then(() => {}).catch(() => {
                    commit('showMessage', {
                      message: '没有找到该用户',
                      type: 'warning'
                    })
                  })
              }
            });
          }, 500);
        }
      }).catch(err => {
        console.log(err)
      })
    },
    login(context) {
      tim
        .login(JSON.parse(localStorage.getItem('sign')))
        .then(async () => {
          await context.commit('toggleIsLogin', true)
          context.commit('startComputeCurrent')
          await context.dispatch('getTel')
        })
        .catch(imError => {
          if (imError.code === 2000) {
            Message.error(imError.message + ', 请检查是否正确填写了 SDKAPPID')
          } else {
            Message.error(imError.message)
          }
        })
    },
    logout(context) {
      // 若有当前会话，在退出登录时已读上报
      if (context.rootState.conversation.currentConversation.conversationID) {
        tim.setMessageRead({
          conversationID: context.rootState.conversation.currentConversation.conversationID
        })
      }
      tim.logout().then(() => {
        context.commit('toggleIsLogin')
        context.commit('stopComputeCurrent')
        context.commit('reset')
      })
    }
  }
}

export default user