import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    redirect: "/HomePage",
    children: [{
        path: "/HomePage", // 首页
        name: "HomePage",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/homePage.vue"
          ),
      },
      {
        path: "/goodslist", // 商品分类
        name: "goodslist",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/order/goodslist.vue"
          ),
      },
      {
        path: "/detail", // 商品详情
        name: "detail",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/order/detail/index.vue"
          ),
      },
      {
        path: "/order", // 确认订单
        name: "order",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/shopping/sure.vue"
          ),
      },
      {
        path: "/shoppingCartItem", // 购物车
        name: "shoppingCartItem",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/shopping/cart.vue"
          ),
      },
      {
        path: "/payment", // 付款
        name: "payment",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/shopping/payment.vue"
          ),
      },
      {
        path: "/activity", // 活动公告
        name: "activity",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/activities/list.vue"
          ),
      },
      {
        path: "/actProfile", // 活动详情
        name: "actProfile",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/activities/detail.vue"
          ),
      },
      {
        path: "/pc_agree", // 隐私政策
        name: "pc_agree",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/agreement.vue"
          ),
      },
      {
        path: "/pc_policy", // 用户协议
        name: "pc_policy",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/agreement.vue"
          ),
      },
      {
        path: "/myProfile", // 个人中心-我的信息
        name: "myProfile",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/Personalizar/index.vue"
          ),
      },
      {
        path: "/myFavorites", // 个人中心-我的收藏
        name: "myFavorites",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/Personalizar/index.vue"
          ),
      },
      {
        path: "/myOrders", // 个人中心-我的订单
        name: "myOrders",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/Personalizar/index.vue"
          ),
      },
      {
        path: "/cipher", // 个人中心-修改密码
        name: "cipher",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/Personalizar/index.vue"
          ),
      },
      {
        path: "/schedule", // 我的订单-订单详情
        name: "schedule",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/Personalizar/schedule.vue"
          ),
      },
    ]
  },
  {
    path: "/login", // 登录
    name: "login",
    component: () =>
      import(
        /* webpackChunkName: "group-foo" */
        "../components/login.vue"
      ),
  },
  {
    path: "/customized", // 定制设计
    name: "customized",
    component: () =>
      import(
        /* webpackChunkName: "group-foo" */
        "../components/Custom/index.vue"
      ),
  },
  {
    path: "/design", // 定制设计-定制详情
    name: "design",
    component: () =>
      import(
        /* webpackChunkName: "group-foo" */
        "../components/Custom/index.vue"
      ),
  },
  {
    path: "/agreement", // app-隐私协议
    name: "agreement",
    component: () => import("../views/app/agreement.vue")
  },
  {
    path: "/policy", // app-用户协议
    name: "policy",
    component: () => import("../views/app/policy.vue")
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  const tokenStr = window.localStorage.getItem("token");
  if (tokenStr) {
    next();
  } else {
    console.log(to.path)
    if (to.path === "/shoppingCartItem" || to.path === "/order" || to.path === "/payment" || to.path === "/myProfile" || to.path === "/myFavorites" || to.path === "/cipher" || to.path === "/myOrders" || to.path === "/schedule" || to.path === "/design") {
      next("/login");
    } else {
      next();
    }
  }
});
export default router