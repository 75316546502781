import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import postApi from "./components/utils/api";
import './assets/css/font.scss';
import { MessageBox, Row, Col, Button, Input, Loading, Dialog, Dropdown, DropdownMenu, DropdownItem, Checkbox, CheckboxGroup } from 'element-ui'
import Avatar from './components/im/avatar.vue'
import tim from 'tim'
import TIM from 'tim-js-sdk'
import VueClipboard from 'vue-clipboard2'
import './assets/icon/iconfont.css'
import './assets/icon/tim.css'
import './assets/css/animate.css'

window.tim = tim
window.TIM = TIM
Vue.prototype.$bus = new Vue() // event Bus 用于无关系组件间的通信。
Vue.prototype.tim = tim
Vue.prototype.TIM = TIM
Vue.use(VueClipboard)

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.prototype.$http = postApi;
Vue.prototype.$confirm = MessageBox.confirm
Vue.use(Button)
Vue.use(Row)
Vue.use(Col)
Vue.use(Input)
Vue.use(Loading)
Vue.use(Dialog)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.component('avatar', Avatar)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
