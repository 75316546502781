const goodsModules = {
  state: {
    goods: {},
    formdata: {},
    tel: null,
    cars_num: null,
    avatar: null
  },
  mutations: {
    savegoods(state, payload) {
      state.goods = payload
    },
    saveavatar(state, payload) {
      state.avatar = payload
    },
    saveform(state, payload) {
      state.formdata = payload
    },
    savetel(state, payload) {
      state.tel = payload
    },
    savenum(state, payload) {
      state.cars_num = payload
    }
  },
  getters: {
    getnum: state => {
      return state.cars_num
    },
    gettel: state => {
      return state.tel
    },
    getgoods: state => {
      return state.goods
    },
    getform: state => {
      return state.formdata
    },
    getavatar: state => {
      return state.avatar
    }
  }
}

export default goodsModules